import React, { useState } from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import "./provoke.css";
import photo from "./rainbow-vortex.svg";

import Navbar from "../pers-navbar.component";
import annyang from 'annyang';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import MicOffIcon from '@mui/icons-material/MicOff';

const Home = () => {
    const [topic, setTopic] = useState();
    const [party, setParty] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [capturedSpeech, setCapturedSpeech] = useState('');

    const generate = () => {
        setTopic(<CircularProgress size="10rem" color="inherit"/>);
        axios.get(process.env.REACT_APP_API_URL+"/gen/generate")
            .then(res => {
                setTimeout(() => {
                    if (party)
                        speak(res.data.text);
                    setTopic(res.data.text);
                }, 250);
            });
    };

    const generateDeep = () => {
        console.log("old")
        setTopic(<CircularProgress size="10rem" color="inherit"/>);
        axios.post(process.env.REACT_APP_API_URL+"/gen/generateDeep", { question: topic })
            .then(res => {
                setTimeout(() => {
                    if (party)
                        speak(res.data.text);
                    setTopic(res.data.text);
                }, 250);
            });
    };

    const toggleParty = () => {
        setParty(party => !party);
    };

    const startListening = () => {
        if (annyang) {
            annyang.start();
            setIsListening(true);

            annyang.addCallback('result', function (phrases) {
                console.log(phrases[0]);
                stopListening();
                generateDeepWithSpeech(phrases[0]);
            });
        }
    };

    const stopListening = () => {
        if (annyang) {
            annyang.abort();
            setIsListening(false);
        }
    };

    const generateDeepWithSpeech = (speechText) => {
        setTopic(<CircularProgress size="10rem" color="inherit"/>);
        console.log("speech")
        axios.post(process.env.REACT_APP_API_URL+"/gen/generateDeep", { question: speechText })
            .then(res => {
                setTimeout(() => {
                    speak(res.data.text);
                    setTopic(res.data.text);
                }, 250);
            });
    };

    const speak = (text) => {
        window.responsiveVoice.speak(text);
        // window.responsiveVoice.speak(text, {
        //     onstart: () => {},
        //     onend: () => {},
        //     onerror: (event) => {}
        // });
    };

    return (
        <div className="container-fluid px-0" style={{ backgroundSize: "cover", height: "100vh", backgroundImage: `url(${photo})` }}>
            <title>Provoke</title>
            <Navbar />
            <CelebrationIcon style={{ color: party ? "white" : "grey" }} onClick={toggleParty}></CelebrationIcon>
            <div style={{ textAlign: "center" }}>
                <h2 className="provtitle">Sometimes all it takes is one question to start a conversation that never ends...</h2>
                <div className="qTitle">{topic}</div>
                {party && (
                    <div style={{ marginTop: "1rem" }}>
                        <button className="genBt" onClick={isListening ? stopListening : startListening}>
                            {isListening ? <MicOffIcon /> : <KeyboardVoiceIcon />}
                        </button>
                        {/* <div className="captured-speech">{capturedSpeech}</div> */}
                    </div>
                )}
            </div>
            {!party && (
                <>
                    <div className="bt2 sendbt genBt" onClick={generate}>Generate</div>
                    <div className="bt2 sendbt genBt2" hidden={typeof(topic) !== 'string'} onClick={generateDeep}>Dig Deeper</div>
                </>
            )}
        </div>
    );
};

export default Home;
