import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./viewRecipie.css";
import { Buffer } from 'buffer';
import Navbar from "../pers-navbar.component";
import { useParams } from 'react-router-dom';

const EditRecipie = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`https://adits-notesapp.herokuapp.com/recipie/recipie/${id}`)
      .then(response => {
        const fetchedData = response.data;
        setData(fetchedData);
      })
      .catch(error => {
        console.log(error);
        // Handle error if needed
      });
  }, [id]);

  if (!data) {
    // Data is still being fetched
    return <p>Loading...</p>;
  }

  return (
    <div>
      <title>{data.name} Recipie</title>
      <Navbar />

      <div className="rTitle">
        <div className="rTopic">{data.name}</div>
        <div className="align">
          <div className="rInfo">Cuisine: {data.cuisine} | {data.meal.toString().replace("0", "All").replace("1", "Sauces/Preps").replace("2", "Apps").replace("3", "Meal").replace("4", "Dessert")} | Time: {data.time} | Serving: {data.serving} | Calories: {data.cal}</div>
          <div className="rDes">{data.description}</div>
        </div>
        {imageView(data.image)}
      </div>

      <table className="table table-lg">
        <tbody>
          <tr className="rFocus">
            <td style={{backgroundColor:"transparent"}}>
              <div className="lblsep rLbl">Ingredients</div>
              <ul>{ingredList(data.ingreds)}</ul>
            </td>
            <td style={{backgroundColor:"transparent"}}>
              <div className="lblsep rLbl">Instructions</div>
              <ol className="insList">{instructList(data.steps)}</ol>
            </td>
          </tr>
          <tr className="rLink">
            <td style={{backgroundColor:"transparent"}}>
              <div className="rAlt peal rLbl">Links</div>
              <ul>{linkList(data.outsideLinks)}</ul>
            </td>
            <td style={{backgroundColor:"transparent"}}>
              <div className="rAlt peal rLbl">Videos</div>
              <ul>{vidList(data.videoLinks)}</ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const imageView = (image) => {
  if (Buffer.from(image).toString("base64") !== "") {
    return <div className="fifty align"><img className="img-fluid rImage" src={`data:image/jpeg;base64,${Buffer.from(image).toString("base64")}`} alt="recipie" /></div>;
  }
  return null;
};

const ingredList = (ingreds) => {
  return ingreds.map((ingred,index) => {
    return <li className="ingred" key={"ing"+index}>{ingred}</li>;
  });
};

const instructList = (steps) => {
  return steps.map((step, index) => {
    return <li className="ingred" key={"step"+index}>{step}</li>;
  });
};

const vidList = (videoLinks) => {
  return videoLinks.map((link,index) => {
    var point = link.indexOf(": ");
    var shortLink = '';
    if (link === "") return null;
    if (point !== -1) {
      shortLink = link.substring(point + 2);
      if (shortLink.indexOf("http") === -1) shortLink = `https://${shortLink}`;
      return <li className=" peal rAlt"><a target="_blank" rel="noopener noreferrer" href={shortLink} className=" rAlt" key={"vid"+index}>{link.substring(0, point)}</a></li>;
    } else {
      if (link.indexOf("http") === -1) link = `https://${link}`;
      return <li className=" peal rAlt"><a target="_blank" rel="noopener noreferrer" href={link} className=" rAlt" key={"vid"+index}>Video</a></li>;
    }
  });
};

const linkList = (outsideLinks) => {
  return outsideLinks.map((link,index) => {
    var point = link.indexOf(": ");
    var shortLink = '';
    if (link === "") return null;
    if (point !== -1) {
      shortLink = link.substring(point + 2);
      if (shortLink.indexOf("http") === -1) shortLink = `https://${shortLink}`;
      return <li className=" peal rAlt"><a target="_blank" rel="noopener noreferrer" href={shortLink} className=" rAlt" key={"link"+index}>{link.substring(0, point)}</a></li>;
    } else {
      if (link.indexOf("http") === -1) link = `https://${link}`;
      return <li className=" peal rAlt"><a target="_blank" rel="noopener noreferrer" href={link} className=" rAlt" key={"link"+index}>Video</a></li>;
    }
  });
};

export default EditRecipie;
