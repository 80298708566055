//import {BrowserRouter as Router, Route} from "react-router-dom";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
import AnimatedCursor from "react-animated-cursor"
import Home from "./components/home.component";

//Professional
import Professional from "./components/all_professional/professional.component";
import Allergy from "./components/allergies.component";

//Personal
import Blog from "./components/all_blog/blog.component";
import ViewRecipie from "./components/all_recipie/viewRecipie.component";
import Recipies from "./components/all_recipie/recipies.component";
// import Recipies3 from "./components/all_recipie/recipiebud.component";
import Provoke from "./components/AI/provoke.component";

//PP
// import CRM from "./components/pp/crm.component";
// import Notes from "./components/pp/notes-list.component";

function App() {  
 
  return (

    <Router>
          <AnimatedCursor
      innerSize={8}
      outerSize={8}
      color='255,214,0'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',      ]}
    />

      <Routes>  
      <Route path="/" exact element={<Home/>}/>
      <Route path="/blog" exact element={<Blog/>}/>
      <Route path="/allergies" exact element={<Allergy/>}/>
      <Route path="/recipies" exact element={<Recipies/>}/>
      {/* <Route path="/recipies3" exact element={<Recipies3/>}/> */}
      <Route path="/recipies/view/:id/*" exact element={<ViewRecipie/>}/>
      <Route path="/professional" exact element={<Professional/>}/>
      <Route path="/provoke" exact element={<Provoke/>}/>
      {/* <Route path="/project-playground" exact element={<Notes/>}/> */}
      
      {/* <Route path="/project-playground/birthdays" exact element={<CRM/>}/> */}
    </Routes>
  
    </Router>
    
  );
}

export default App;
