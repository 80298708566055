import { Component } from 'react';
import axios from 'axios';
import "./blog.css";
import Navbar from "../pers-navbar.component";
// import AddBlog from '../../admin/admin_fronteditors/edit-post.component';

const Blog = props => (
    
    <div className="blogpost">
        <div className="blogttl" >{props.blog.title}</div>
        <div className="blogdate">{(new Date(props.blog.date)).toLocaleString().substring(0, (new Date(props.blog.date)).toLocaleString().indexOf('/202')+5)}</div>
        {/* <p className="align"><div className="blogimgwrapper"><img className="blogimage" src={"data:image/jpeg;base64,"+(new Buffer.from(props.blog.image).toString("base64").toString("base64"))}   alt="blog"/></div></p> */}
        
        {/* {props.blog.image!=="" ? <div className="align"><div className="blogimgwrapper"><img className="blogimage" src={props.blog.image}   alt="blog"/></div></div> : ""} */}
        {props.blog.image!=="" ? <div className="align"><div className="blogimgwrapper"><iframe style={{"border": 0}}
  src={props.blog.image}
></iframe></div></div> : ""}
        
        {/* <texrea className="blogdetes" disabled rows="20">{props.blog.post}</textarea> */}
        <div className="blogdetes">{props.blog.post}</div>
    </div>

)

export default class BlogPosts extends Component {
    constructor(props) {
        super(props);
        // this.changeAlbum = this.changeAlbum.bind(this);
        this.pullPosts = this.pullPosts.bind(this);
        // this.create = this.create.bind(this);
        this.close  = this.close.bind(this);

        this.state = {
            posts: [],
            album: "Personal",
            lastevent: "",
            vd: <></>,
        }

    }

    componentDidMount(){
        this.pullPosts();
    }

    close(){     
        this.setState({
            vd: Component,
        });
        this.pullPosts()
    }

    // create(e){
    //     e.preventDefault()
    //     localStorage.setItem('token', "")
    //     this.setState({
    //         dialog: !this.state.dialog,
    //         vd: <AddBlog close={this.close}/>
    //     })
    //     // this.showNotifications();
    // }

    blogList() {
        return this.state.posts.sort(function(a, b) {
            return (a["date"] < b["date"]) ? 1 : ((a["date"] > b["date"]) ? -1 : 0);
          }).map(currentpost => {
            return <Blog blog={currentpost} key={currentpost._id}/>
        });
    }

    pullPosts(){
        axios.get(process.env.REACT_APP_API_URL+"/blog/of/adit/Personal")
                .then(response => {
                    var main = response.data;
                    axios.get(process.env.REACT_APP_API_URL+"/blog/of/guest/Personal")
                .then(response => {
                    this.setState({posts: response.data.concat(main)})
                    // console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                  //  window.location = "/access";
                });
                })
                .catch((error) => {
                    console.log(error);
                  //  window.location = "/access";
                });
        }


    render() {
        return (
            <div className="container-fluid">         
             <Navbar />   
                <title>Adit's Literature Blog</title>
               <div className="blogtitle">Adit's Literature Blog </div>
               {/* <div style={{'backgroundColor':'#1A4861','textAlign': 'right','right': '15%'}}><input
                                    type="submit"
                                    value="Guest Post"
                                    className="bt1"
                                    
                                    onClick={this.create}
                                /></div> */}
               {this.state.vd}
                {this.blogList()}
            </div>
        )
    }
}