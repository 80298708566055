import { Component } from 'react';
import { Dropdown, DropdownItem} from 'react-bootstrap';
import "./navbar.css"
import "bootstrap/dist/css/bootstrap.min.css"


export default class Navbar extends Component {
    render() {
        return (
            <div className="dropWrap" style={{"position: relative; z-index": 2}}>
            <Dropdown>
            <Dropdown.Toggle className="drop"> 
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropmenu">
                 <Dropdown.Item className= "dropcard" href="/provoke">Provoke</Dropdown.Item>
                 <Dropdown.Item className= "dropcard" href="/professional">Professional</Dropdown.Item>
                 {/* <Dropdown.Item className= "dropcard" href="/project-playground">Add-It!</Dropdown.Item> */}
                <Dropdown.Item className= "dropcard" href="/blog">Blog</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/recipies">Recipies</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/allergies">Allergy List</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/">Home</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown></div>
        )
    }
}
